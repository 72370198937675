// extracted by mini-css-extract-plugin
export var bluetext = "index-module--bluetext--x6oAc";
export var buttons = "index-module--buttons--1EDc9";
export var ceroxinLink = "index-module--ceroxinLink--F5Si4";
export var decor = "index-module--decor--qy+j2";
export var desc = "index-module--desc--WUyzJ";
export var models = "index-module--models--gnVc8";
export var name = "index-module--name--Cegvb";
export var numberPart = "index-module--numberPart--Gd+JN";
export var numbers = "index-module--numbers--y7ApU";
export var numbersDecor = "index-module--numbersDecor--gdUUr";
export var padding = "index-module--padding--svXsK";
export var promo = "index-module--promo--Q8Nm-";
export var promoImage = "index-module--promoImage--SDj7a";
export var reviews = "index-module--reviews--DighI";
export var reviewsBackground = "index-module--reviewsBackground--TsW+z";
export var reviewsBackgroundStaticImage = "index-module--reviewsBackgroundStaticImage--n0AcO";
export var slidingSection = "index-module--slidingSection--6PJIo";
export var slidingSectionOpen = "index-module--slidingSectionOpen--XZ6Bw";
export var surgeriesFooter = "index-module--surgeriesFooter--CpHWu";
export var swiper = "index-module--swiper--Uqs-n";