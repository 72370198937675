import React from "react";

import Surgeries from '../../components/surgeries'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import Layout from "../../containers/layout";
import SEO from "../../components/seo";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Review from '../../components/review'

import * as styles from "../../styles/pages/index.module.css";
import * as sharedStyles from "../../styles/shared.module.css";

const IndexPageTemplate = props => {
    const { data, isEn = false } = props;
    const { site } = data
    const prefix = isEn ? '_en' : ''
    const surgeryTypes = data.surgeryTypes.nodes
    const reviews = data.reviews.nodes
    const models = data.modelPhotos.nodes
  
    const width = typeof window === 'undefined' ? 1920 : document.body.clientWidth
    return (
      <Layout isEn={isEn}>
        <SEO title={site.title || site.title_en} description={site.description || site.description_en} keywords={site.keywords || site.keywords_en} />
        <section className={styles.promo}>
          <StaticImage objectFit="contain" className={styles.promoImage} src="../../media/Victoria.png" alt="Виктория Пластический Хирург" />
          <div className={styles.padding}>
            <p className={styles.bluetext}>
              {  isEn ? 'Plastic surgeon, Doctor of Medical Sciences, Professor' : 'Пластический хирург, Доктор медицинских наук, профессор' }
            </p>
            <h1 className={styles.name}>{isEn ? site.surgeonName_en : site.surgeonName}</h1>
            <p className={styles.desc}>
              { isEn ? 'Plastic surgeon; Doctor of Medical Sciences; Professor of the course of reconstructive-plastic surgery of the State Budgetary Institution of Medical and Biomedical Institution "Vladimirsky MONIKI"; oncologist, maxillofacial surgeon of the medical centre "Skin Clinic"; plastic surgeon of the medical centre "Hartman Clinic"; Member of the Russian Society of Plastic, Reconstructive and Aesthetic Surgeons and Cosmetologists; member of the European Association of Cranio-Maxillofacial Surgeons; author of more than 90 printed works and 12 patents for invention.' :
                'Пластический хирург; доктор медицинских наук; профессор курса реконcтруктивно-пластической хирургии ГБУЗ МО МОНИКИ им. М. Ф. Владимирского; врач-онколог, челюстно-лицевой хирург медицинского центра «Клиника кожи»; пластический хирург медицинского центра «Клиника Хартман»; Член Российского общества пластических, реконструктивных и эстетических хирургов и косметологов; член европейской ассоциации черепно-челюстно-лицевых хирургов; автор более 90 печатных работ и 12 патентов на изобретение.'
              }
            </p>
            <p className={styles.desc}>
              { isEn ? <>
                  <span>Founder of the </span><a className={styles.ceroxinLink} target="blank" rel="noopener noreferrer" href="https://ceroxin.com/">Ceroxin</a><span> project. Repeated participant of international conferences, symposiums on plastic surgery and cosmetology, maxillofacial surgery, oncology. Work experience since 2004.</span>
                </> : <>
                  <span>Основатель проекта </span><a className={styles.ceroxinLink} target="blank" rel="noopener noreferrer" href="https://ceroxin.com/">Ceroxin</a><span>. Неоднократный участник международных конференций, симпозиумов по пластической хирургии и косметологии, челюстно-лицевой хирургии, онкологии. Стаж работы с 2004 года.</span>
                </>
              }
            </p>
            <div className={styles.buttons}>
              <button className="filled" onClick={() =>  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>{ isEn ? 'Make an appointment' : 'Записаться'}</button>
              <Link to={ isEn ? '/en/about' : '/about'}><button>{ isEn ? 'About me' : 'Обо мне'}</button></Link>
            </div>
          </div>
          <div className={styles.numbersDecor}>
            <div className={styles.numbers}>
              <div className={styles.numberPart}>
                <h3>20</h3>
                <p>{isEn ? 'years of practice' : 'лет практики'}</p>
              </div>
              <div className={styles.numberPart}>
                <h3>13000+</h3>
                <p>{ isEn ? 'happy clients' : 'счастливых клиентов'}</p>
              </div>
              <div className={styles.numberPart}>
                <h3>12</h3>
                <p>{ isEn ? 'development patents' : 'патентов на изобретение'}</p>
              </div>
              <div className={styles.numberPart}>
                <h3>90+</h3>
                <p>{ isEn ? 'printed works' : 'печатных работ'}</p>
              </div>
            </div>
          </div>
  
          <div className={styles.decor}>
            <h2>Plastic Surgeon</h2>
          </div>
        </section>
        <div className={styles.surgeriesFooter}>
          <h2 className={sharedStyles.sectionTitle}>{ isEn ? 'Surgeries' : 'Операции' }</h2>
        </div>
        <Surgeries isEn={isEn} noBackground={true} surgeryTypes={surgeryTypes} id="indexSurgeries" />
        <div className={styles.surgeriesFooter}>
          <Link className="link-no-decoration" to={ isEn ? '/en/surgeries' : '/surgeries' }><button>{ isEn ? 'See all' : 'Смотреть все' }</button></Link>
          <h2 className={sharedStyles.sectionTitle}>{ isEn ? 'Reviews' : 'Отзывы' }</h2>
        </div>
        <section className={styles.reviews}>
          {/* <div className={styles.reviewsBackground}>
            <StaticImage src="../../media/BackgroundShift.svg" objectFit="contain" alt="Background transition" className={styles.reviewsBackgroundStaticImage} />
          </div> */}
          <Swiper
            slidesPerView={ width > 1120 ? 2.9 : width < 720 ? 1 : 2 }
            spaceBetween={70}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className={styles.swiper}
          >
            {
              reviews.map((data, i) => 
                <SwiperSlide key={i}>
                  <Review name={data['name' + prefix]} messages={data['reviewMessages' + prefix]} stars={data['reviewStars' + prefix]} />
                </SwiperSlide>
              )
            }
          </Swiper>
        </section>
        <section className={styles.models + ' modelsSection'}>
          <Swiper
            slidesPerView={ width > 690 ? 2.3 : width < 590 ? 1 : 1.5 } // 69? Nice!
            spaceBetween={70}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className={styles.swiper}
          >
            {
              models.map(({ photo }, i) => {
                const { asset, alt, altEn = 'undefined' } = photo
                return <SwiperSlide key={i}>
                    <GatsbyImage image={asset.gatsbyImageData} alt={isEn ? altEn : alt} />
                  </SwiperSlide>
                }
              )
            }
          </Swiper>
        </section>
      </Layout>
    );
  };
  
  export default IndexPageTemplate;